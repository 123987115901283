import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/contact.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { ToastContainer, toast } from "react-toastify";

function ContactContainer() {
  const [response, setResponse] = useState([]);
  const apiUrl = "https://btm-textile.herokuapp.com";

  const fetch = () => {
    axios.get(`${apiUrl}/contact/getData`).then((res) => {
      //   const arr = res.data;
      //   console.log(res.data.Data);
      setResponse(res.data.Data);
      //   console.log(response);
    });
  };

  const tableData = response.map((item, index) => {
    return (
      <tr>
        <td>{item.name}</td>
        <td>{item.email}</td>
        <td>{item.phone}</td>
        <td>
          <Tooltip
            title={item.message}
            position="left"
            trigger="mouseenter"
            arrow="true"
          >
            <p>{item.message.slice(0, 13)}</p>
          </Tooltip>
        </td>
        <td>
          <button
            onClick={() => {
              axios
                .post(
                  `${apiUrl}/contact/delete/${item._id}`
                )
                .then((res) => {
                  console.log(res);
                  if (res.data.success) {
                    fetch();
                    toast.success("Message Deleted");
                  }
                });
            }}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="contact-container">
      <h1>Queries</h1>
      <ToastContainer />
      <Table striped bordered hover size="lg" className="contact-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Message</th>
            <th>Delete Message</th>
          </tr>
        </thead>
        <tbody>{tableData}</tbody>
      </Table>
    </div>
  );
}

export default ContactContainer;
