import React, { useState, useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/contact.css";
import "../assets/css/Products.css";
import "react-tippy/dist/tippy.css";
import { Table, Tab, Tabs } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

function Gallery() {
  const [response, setResponse] = useState([]);
  const apiUrl = "https://btm-textile.herokuapp.com";

  const [newProduct, setNewProduct] = useState({
    name: "",
    data: "",
    category: "",
  });

  const fetch = () => {
    axios
      .get(`${apiUrl}/api/gallery/getData`)
      .then((res) => {
        setResponse(res.data.Data);
      });
  };

  const tableData = response.map((item, index) => {
    return (
      <tr>
        <td>{item.heading}</td>
        <td>
          <Zoom>
            <img src={item.url} height="100"></img>
          </Zoom>
        </td>
        <td>
          <button
            onClick={() => {
              axios
                .post(
                  `${apiUrl}/api/gallery/delete/${item._id}`
                )
                .then((res) => {
                  if (res.data.success) {
                    toast(`Deleted ${item.heading} Successfully`);
                    fetch();
                  }
                  // console.log(response);
                });
            }}
          >
            Delete
          </button>
        </td>
        <td></td>
      </tr>
    );
  });

  useEffect(() => {
    fetch();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer></ToastContainer>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab">
        <Tab eventKey="home" title="List of Gallery Items">
          <div className="contact-container product-container">
            <h1>Gallery</h1>
            <ToastContainer />
            <Table striped bordered hover size="lg" className="contact-table">
              <thead>
                <tr>
                  <th>Heading</th>
                  <th>Photo</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="profile" title="Add Card">
          <h1>Add Card</h1>
          <div className="flex-center-col">
            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setNewProduct({ ...newProduct, heading: e.target.value });
                console.log(e.target.value);
              }}
              placeholder="Enter Heading"
            ></input>

            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setNewProduct({ ...newProduct, url: e.target.value });
              }}
              placeholder="Enter URL of Image"
            ></input>

            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setNewProduct({ ...newProduct, description: e.target.value });
              }}
              placeholder="Enter Description of Image"
            ></input>

            <button
              id="sub-img"
              onClick={() => {
                var body = {
                  heading: newProduct.heading,
                  url: newProduct.url,
                  description: newProduct.description,
                };
                console.log(body);
                axios
                  .post(`${apiUrl}/api/gallery`, body)
                  .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                      toast(`${body.heading} Added Successfully`);
                      fetch();
                    }
                    // fetch();
                  });
              }}
              className="product-add-btn"
            >
              Submit
            </button>
          </div>
        </Tab>
      </Tabs>
    </React.Fragment>
  );
}

export default Gallery;
