import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/css/About.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiUrl = "https://btm-textile.herokuapp.com";

function AboutContainer() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [content, setContent] = useState({
    name: "",
    data: "",
  });

  const fetch = () => {
    axios
      .get(`${apiUrl}/about/getData`)
      .then((response) => {
        // response.data.Data[0];
        setContent({
          ...content,
          name: response.data.Data[0].name,
          data: response.data.Data[0].data,
        });
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const handleEdit = () => {
    setIsDisabled(false);
  };

  const handleAboutChange = (e) => {
    console.log(e.target.value);
    content.data = e.target.value;
  };

  const handleClick = (event) => {
    event.preventDefault();
    console.log("Button Hit");
    const body = content;
    axios
      .post(`${apiUrl}/about/update`, body)
      .then((res) => {
        console.log(res.data.data.data);
        if (res.data.success) {
          toast("Content Updated");
          setContent({
            ...content,
            name: res.data.data.name,
            data: res.data.data.data,
          });
        }
      });
  };

  return (
    <div className="about-container">
      <ToastContainer />
      <h1>{content.name}</h1>
      <textarea
        type="textarea"
        disabled={isDisabled}
        defaultValue={content.data}
        onChange={handleAboutChange}
      ></textarea>
      <div className="flex-row">
        <button className="red-btn" onClick={handleEdit}>
          Edit Content
        </button>
        <input type="submit" className="red-btn" onClick={handleClick}></input>
      </div>
    </div>
  );
}

export default AboutContainer;
