import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import "./fonts/Gobold Hollow Bold Italic.otf";
import "./fonts/Gobold Hollow Bold.otf";
import "./fonts/BENGUIAT.TTF";

require("dotenv").config();

const domain = "btm-textile.us.auth0.com";
const clientId = "lpCzTBYXEL3Irfa2nPcwQ6FJtVrFrZmE";

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    audience="https://btm-textile.us.auth0.com/api/v2/"
    redirectUri={window.location.origin}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
