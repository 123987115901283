import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/contact.css";
import "../assets/css/Products.css";
import "react-tippy/dist/tippy.css";
import { Table, Tab, Tabs } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const apiUrl = "https://btm-textile.herokuapp.com";

function ContactContainer() {
  const [response, setResponse] = useState([]);

  const [newProduct, setNewProduct] = useState({
    name: "",
    data: "",
    category: "",
    subcategories: "",
    link: "",
    seoContent: "",
    title: "",
    description: "",
  });

  const [seoContent, setSeoContent] = useState("");
  const [category, setCategory] = useState("");

  const fetch = () => {
    axios
      .get(`${apiUrl}/api/products/getData`)
      .then((res) => {
        setResponse(res.data.Products);
      });
  };

  const fetchContent = () => {
    const body = {
      category: category,
    };
    console.log(body);
    axios
      .post(
        `${apiUrl}/api/products/seoContent/getData`,
        body
      )
      .then((res) => {
        setSeoContent(res.data.data);
        console.log(res);
      });
  };

  const tableData = response.map((item, index) => {
    // console.log(edit);
    return (
      <tr id={index}>
        <td>{item.name}</td>
        <td>{item.data}</td>
        <td>
          <button
            onClick={() => {
              axios
                .post(
                  `${apiUrl}/api/products/delete/${item._id}`
                )
                .then((res) => {
                  if (res.data.success) {
                    toast(`Deleted ${item.name} Successfully`);
                    fetch();
                  }
                });
            }}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    fetch();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer></ToastContainer>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab">
        <Tab eventKey="home" title="List of Products">
          <div className="contact-container product-container">
            <h1>Products</h1>
            <ToastContainer />
            <Table striped bordered hover size="lg" className="contact-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Image Link</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="profile" title="Add Product">
          <h1>Add Product</h1>
          <div className="flex-center-col">
            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setNewProduct({ ...newProduct, name: e.target.value });
              }}
              placeholder="Enter Name of Product"
              autoComplete="off"
            ></input>

            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setNewProduct({ ...newProduct, data: e.target.value });
              }}
              placeholder="Enter URL of Cover Image"
              autoComplete="off"
            ></input>

            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setNewProduct({ ...newProduct, link: e.target.value });
              }}
              placeholder="Enter Link like /curtains"
              autoComplete="off"
            ></input>

            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setNewProduct({ ...newProduct, title: e.target.value });
              }}
              placeholder="Enter Title of Page"
              autoComplete="off"
            ></input>

            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setNewProduct({ ...newProduct, description: e.target.value });
              }}
              placeholder="Enter Meta Description"
              autoComplete="off"
            ></input>

            <ReactQuill theme="snow" value={seoContent} onChange={setSeoContent} />


            <button
              id="sub-img"
              onClick={() => {
                var body = {
                  name: newProduct.name,
                  category: newProduct.name,
                  subcategory: [],
                  data: newProduct.data,
                  link: newProduct.link,
                  seoContent: seoContent,
                  title: newProduct.title,
                  description: newProduct.description,
                };
                console.log(body);
                axios
                  .post(`${apiUrl}/api/products/add`, body)
                  .then((res) => {
                    if (res.data.success) {
                      toast(`${body.name} Added Successfully`);
                      fetch();
                    }
                  });
              }}
              className="product-add-btn"
            >
              Submit
            </button>
          </div>
        </Tab>
        <Tab eventKey="seo" title="Seo Content">
          <div className="flex-col-center">
            <h1>Edit Content</h1>
            <select
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              required
            >
              <option value="" selected>
                Choose a Category
              </option>
              {response.map((item) => {
                return <option value={item.name}>{item.name}</option>;
              })}
            </select>
            <button
              id="sub-btn-red"
              onClick={() => {
                fetchContent();
              }}
            >
              Get Content
            </button>

            <ReactQuill theme="snow" value={seoContent} onChange={setSeoContent} />

            <button
              type="submit"
              id="sub-btn-red"
              onClick={() => {
                const body = {
                  category: category,
                  seoContent: seoContent,
                };
                axios
                  .post(
                    `${apiUrl}/api/products/seoContent/updateData`,
                    body
                  )
                  .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                      toast("Updated Successfully !");
                    }
                  });
              }}
            >
              Submit
            </button>
          </div>
        </Tab>
      </Tabs>
    </React.Fragment>
  );
}

export default ContactContainer;
