import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Nav, NavLink, Navbar } from "react-bootstrap";
import "../assets/css/Nav.css";
import About from "../views/AboutContainer";
import Contact from "../views/ContactContainer";
import Products from "../views/ProductsContainer";
import Subcategory from "../views/Products/Subcategory";
import Gallery from "../views/Gallery";
import Images from "../views/Products/Images";
import Home from "../views/Home";

import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";

export default function Naver() {
  const { logout, isAuthenticated } = useAuth0();
  return (
    isAuthenticated && (
      <Router>
        <div className="Nav">
          <Link to="/">
            <h2>BTM Admin</h2>
          </Link>
          <div className="nav-collap">
            <ul>
              <Link to="/about">
                <li>About</li>
              </Link>
              <Link to="/contact">
                <li>Messages</li>
              </Link>
              <Link to="/add-products">
                <li>Add Products</li>
              </Link>
              <Link to="/add-subcategory">
                <li>Add Subcategory</li>
              </Link>
              <Link to="/add-subcategory-images">
                <li>Add Images</li>
              </Link>
              <Link to="/Gallery">
                <li>Gallery</li>
              </Link>
            </ul>
          </div>
          <button onClick={() => logout()}>Log Out</button>
        </div>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/add-products/">
            <Products />
          </Route>
          <Route path="/add-subcategory">
            <Subcategory />
          </Route>
          <Route path="/add-subcategory-images">
            <Images />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    )
  );
}
