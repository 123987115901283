import React, { useState, useEffect } from "react";
import { Table, Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "../assets/css/contact.css";
import "../assets/css/Products.css";
import "react-tippy/dist/tippy.css";

function Home() {
  const [response, setResponse] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const apiUrl = "https://btm-textile.herokuapp.com";

  const fetch = () => {
    axios.get(`${apiUrl}/api/home/getData`).then((res) => {
      setResponse(res.data.Data);
    });
  };

  const handleAddImage = (e) => {
    e.preventDefault();
    const body = {
      image: imageURL,
    };
    axios.post(`${apiUrl}/api/home/`, body).then((res) => {
      if (res.data.success) {
        toast("Image Added Successfully");
        fetch();
      }
    });
  };

  const tableData = response.map((item, index) => {
    return (
      <tr>
        <td>
          <Zoom>
            <img src={item.image} height="100"></img>
          </Zoom>
        </td>
        <td>{item.image}</td>

        <td>
          <img
            src="https://img.icons8.com/ios-filled/50/000000/delete-forever.png"
            width="30"
            height="30"
            onClick={() => {
              axios
                .post(
                  `${apiUrl}/api/home/delete/${item._id}`
                )
                .then((res) => {
                  console.log(res);
                  if (res.data.success) {
                    fetch();
                    toast.success(`Image Deleted Successfully..`);
                  }
                });
            }}
          />
        </td>
      </tr>
    );
  });

  useEffect(() => {
    fetch();
  }, []);
  return (
    <React.Fragment>
      <ToastContainer></ToastContainer>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab">
        <Tab eventKey="home" title="Show Products">
          <div className="carousel-table">
            <h1>Carousel</h1>
            <Table striped bordered hover size="lg" className="contact-table">
              <thead>
                <tr>
                  <th>Image(Click to Zoom)</th>
                  <th>URL</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="profile" title="Add Image">
          <h1>Add Image</h1>
          <form onSubmit={handleAddImage}>
            <input
              type="text"
              id="img-form"
              placeholder="Enter Image URL"
              onChange={(e) => {
                setImageURL(e.target.value);
              }}
            ></input>
            <button type="submit" id="sub-img">
              Submit
            </button>
          </form>
        </Tab>
      </Tabs>
    </React.Fragment>
  );
}

export default Home;
