import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/contact.css";
import "../../assets/css/Products.css";
import "react-tippy/dist/tippy.css";
import { ToastContainer, toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Subcategory() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(false);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubCategory] = useState("");
  const [Subname, setSubName] = useState("");
  const [content, setContent] = useState("");
  const [subContent, setSubContent] = useState("");
  const [subcategoryData, setSubcategoryData] = useState([]);
  const apiUrl = "https://btm-textile.herokuapp.com";


  function getSubcategory(product) {
    const body = {
      category: product,
    };
    axios
      .post(`${apiUrl}/api/products/get-subcategory`, body)
      .then((data) => {
        setSubcategoryData(data.data.message);
      });
  }

  const fetchProductsData = () => {
    axios
      .get(`${apiUrl}/api/products/getData`)
      .then((res) => {
        setProducts(res.data.Products);
      });
  };

  const fetchContent = () => {
    const body = {
      category: category,
      subcategory: subcategory,
    };
    console.log(body);
    axios
      .post(
        `${apiUrl}/api/products/subcategory/getData`,
        body
      )
      .then((res) => {
        setSubContent(res.data.data);
        console.log(res);
      });
  };

  useEffect(() => {
    fetchProductsData();
  }, []);

  const handleAddSubcategoryForm = (e) => {
    e.preventDefault();

    if (name === "") {
      setError(true);
      toast.error("Invalid Input from Dropdown");
      return;
    }

    var body = {
      name: name,
      subcategory: {
        name: Subname,
        images: [],
        content: content,
      },
    };

    axios
      .post(`${apiUrl}/api/products/add-subcategory`, body)
      .then((resData) => {
        console.log(resData);
        if (resData.data.success) {
          Array.from(document.querySelectorAll("input")).forEach(
            (input) => (input.value = "")
          );
          toast("Subcategory Added Successfully");
          setContent("");
          setSubName("");
          setError(false);
        }
      });
  };

  const handleAddSubcategoryImageForm = () => {
    console.log("Working");
  };

  return (
    <div>
      <ToastContainer />
      <Tabs defaultActiveKey="home" id="uncontrolled-tab">
        <Tab
          eventKey="home"
          title="Add Sub Category"
          className="flex-col-center"
        >
          <h1>Add Subcategory</h1>

          <form onSubmit={handleAddSubcategoryForm}>
            <select
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            >
              <option value="" selected>
                Choose a value
              </option>

              {products.map((item) => {
                return <option value={item.name}>{item.name}</option>;
              })}
            </select>
            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setSubName(e.target.value);
              }}
              required
              placeholder="Enter Subcategory Name"
            ></input>
            <input
              type="text"
              id="img-form"
              onChange={(e) => {
                setContent(e.target.value);
              }}
              required
              placeholder="Enter Content of Sub category"
              required
            ></input>
            <input type="submit" id="sub-btn-red" value="Submit"></input>
          </form>
        </Tab>
        <Tab eventKey="" title="Change Subcategory Content">
          <div className="flex-col-center">
            <h1>Edit Content</h1>
            <select
              onChange={(e) => {
                setCategory(e.target.value);
                getSubcategory(e.target.value);
              }}
              required
            >
              <option value="" selected>
                Choose a Category
              </option>
              {products.map((item) => {
                return <option value={item.name}>{item.name}</option>;
              })}
            </select>{" "}
            <br></br>
            <select
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
              required
            >
              <option value="" selected>
                Choose a Subcategory
              </option>
              {subcategoryData.map((item) => {
                return <option value={item.name}>{item.name}</option>;
              })}
            </select>
            <button
              id="sub-btn-red"
              onClick={() => {
                fetchContent();
              }}
            >
              Get Content
            </button>
            <br></br>
            {/* <input
              type="text"
              id="img-form"
              value={subContent}
              onChange={(e) => {
                setSubContent(e.target.value);
                console.log(subContent);
              }}
            ></input> */}

            <ReactQuill theme="snow" value={subContent} onChange={setSubContent} />

            <button
              type="submit"
              id="sub-btn-red"
              onClick={() => {
                const body = {
                  category: category,
                  subcategory: subcategory,
                  content: subContent,
                };
                axios
                  .post(
                    `${apiUrl}/api/products/subcategory/updateData`,
                    body
                  )
                  .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                      toast("Updated Successfully !");
                    }
                  });
              }}
            >
              Submit
            </button>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}
