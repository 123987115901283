import React from "react";
import "./App.css";
import LoginButton from "./components/Login";
import Profile from "./views/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./assets/loader.gif";

// import Routes from "./Routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const { isLoading } = useAuth0();

  if (isLoading)
    return (
      <div className="Loading">
        <img src={Loader}></img>
        {/* Loading.. */}
      </div>
    );

  return (
    <Router>
      <LoginButton />
      <Profile />
    </Router>
  );
}

export default App;
