import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Particles from "react-particles-js";
import "../assets/css/home.css";

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
      <div className="login-bg">
        <Particles
          params={{
            particles: {
              number: {
                value: 200,
                density: {
                  enable: true,
                  value_area: 1000,
                },
              },
            },
          }}
        />
        <button className="login-btn" onClick={() => loginWithRedirect()}>
          Log In
        </button>
      </div>
    )
  );
};

export default LoginButton;
