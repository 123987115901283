import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Images() {
  const [fields, setFields] = useState([{ value: null }]);
  const [category, setCategory] = useState("");
  const [subcategory, setSubCategory] = useState("");
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [products, setProducts] = useState([]);
  const fieldsArr = [];
  var isSaved = false;
  const apiUrl = "https://btm-textile.herokuapp.com";

  function handleChange(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }

  function getSubcategory(product) {
    const body = {
      category: product,
    };
    axios
      .post(`${apiUrl}/api/products/get-subcategory`, body)
      .then((data) => {
        setSubcategoryData(data.data.message);
      });
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  function handleAddSubcategoryImageForm(e) {
    e.preventDefault();
    if (!isSaved) {
      toast.error("Please Save before Submitting");
      return;
    }
    const body = {
      category: category,
      name: subcategory,
      images: fieldsArr,
    };
    axios
      .post(
        `${apiUrl}/api/products/add-subcategory/image`,
        body
      )
      .then((res) => {
        if (res.data.success) {
          toast.success("Images Added Successfully");
          isSaved = false;
          Array.from(document.querySelectorAll("input")).forEach(
            (input) => (input.value = "")
          );
        }
      });
  }

  function convertToArr() {
    fields.map((item) => {
      fieldsArr.push(item.value);
    });
    isSaved = true;
    toast("Images Saved, Please Submit");
  }

  const fetchProductsData = () => {
    axios
      .get(`${apiUrl}/api/products/getData`)
      .then((res) => {
        setProducts(res.data.Products);
      });
  };

  useEffect(() => {
    fetchProductsData();
    getSubcategory("Carpets");
  }, []);

  return (
    <div className="App">
      <h1>Add Images</h1>

      <form onSubmit={handleAddSubcategoryImageForm}>
        <select
          onChange={(e) => {
            setCategory(e.target.value);
            getSubcategory(e.target.value);
          }}
          required
        >
          <option value="" selected>
            Choose a Category
          </option>
          {products.map((item) => {
            return <option value={item.name}>{item.name}</option>;
          })}
        </select>{" "}
        <br></br>
        <select
          onChange={(e) => {
            setSubCategory(e.target.value);
          }}
          required
        >
          <option value="" selected>
            Choose a Subcategory
          </option>
          {subcategoryData.map((item) => {
            return <option value={item.name}>{item.name}</option>;
          })}
        </select>{" "}
        <br></br>
        {fields.map((field, idx) => {
          return (
            <div key={`${field}-${idx}`}>
              <input
                type="text"
                placeholder="Enter text"
                value={field.value || ""}
                required
                onChange={(e) => handleChange(idx, e)}
              />
              {/* <br></br> */}
              <button type="button" onClick={() => handleRemove(idx)}>
                x
              </button>
            </div>
          );
        })}
        <button type="button" onClick={() => handleAdd()}>
          Add More
        </button>
        <button type="button" onClick={() => convertToArr()}>
          Save
        </button>
        <br></br>
        <button type="submit">Submit</button>
      </form>
      <ToastContainer></ToastContainer>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Images;
